import React, { CSSProperties } from "react";

import { Trans } from 'react-i18next';

type ButtonProps = {
	text: string;
	style?: CSSProperties;
	onClick?: () => void;
};

type SlideControlBtnProps = {
	onClick: () => void;
};

type ActionsBtnGroupProps = {
	primaryBtnText: string;
	secondaryBtnText: string;
	onPrimaryBtnClick?: () => void;
	onSecondaryBtnClick?: () => void;
};

type BurgerMenuButtonProps = {
	onClick: () => void;
	isActive: boolean;
};

export const PrimaryButton = ({ text, onClick, style }: ButtonProps) => {
	return (
		<button className="primary-btn" onClick={onClick} style={style}>
			{text}
		</button>
	);
};

export const SecondaryButton = ({ text, onClick }: ButtonProps) => {
	return (
		<button className="secondary-btn" onClick={onClick}>
			{text}
		</button>
	);
};

export const LoginButton = ({ onClick }: { onClick?: () => void }) => {
	return (
		<button className="login-btn" onClick={onClick}>
			<Trans>
				login
			</Trans>
		</button>
	);
};


export const NextSlideButton = ({ onClick }: SlideControlBtnProps) => {
	return (
		<button className="next-slide-btn" onClick={onClick}>
			<span>
				<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
					<g data-name="24-Arrow-direction-pointer" id="_24-Arrow-direction-pointer">
						<polyline points="1 10 16 30 31 10" style={{ fill: "none", stroke: "#000", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2px" }} />
						<polygon points="31 2 16 22 1 2 31 2" style={{ fill: "none", stroke: "#000", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2px" }} />
					</g>
				</svg>
			</span>
		</button>
	);
};

export const BurgerMenuButton = ({ onClick, isActive }: BurgerMenuButtonProps) => {
	return (
		<span onClick={onClick}>
			{isActive ? (
				<svg fill="none" height="32" stroke="whitesmoke" strokeLinecap="round" strokeLinejoin="round" stroke-width="2" viewBox="0 0 24 24" width="32" xmlns="http://www.w3.org/2000/svg">
					<line x1="18" x2="6" y1="6" y2="18" />
					<line x1="6" x2="18" y1="6" y2="18" />
				</svg>
			) : (
				<svg enableBackground="new 0 0 32 32" height="32px" id="Слой_1" version="1.1" viewBox="0 0 32 32" width="32px" xmlSpace="preserve" xmlnsXlink="http://www.w3.org/1999/xlink">
					<g id="Menu">
						<path d="M1,7h30c0.552,0,1-0.448,1-1c0-0.552-0.448-1-1-1H1C0.448,5,0,5.448,0,6C0,6.552,0.448,7,1,7z" fill="whitesmoke" />
						<path d="M31,15H1c-0.552,0-1,0.448-1,1c0,0.552,0.448,1,1,1h30c0.552,0,1-0.448,1-1C32,15.448,31.552,15,31,15z" fill="whitesmoke" />
						<path d="M31,25H1c-0.552,0-1,0.448-1,1c0,0.552,0.448,1,1,1h30c0.552,0,1-0.448,1-1C32,25.448,31.552,25,31,25z" fill="whitesmoke" />
					</g>
					<g />
					<g />
					<g />
					<g />
					<g />
					<g />
				</svg>
			)}
		</span>
	);
};

export const PrevSlideButton = ({ onClick }: SlideControlBtnProps) => {
	return (
		<button className="prev-slide-btn" onClick={onClick}>
			<span>
				<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
					<g data-name="23-Arrow-direction-pointer" id="_23-Arrow-direction-pointer">
						<polyline points="31 22 16 2 1 22" style={{ fill: "none", stroke: "#000", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2px" }} />
						<polygon points="1 30 16 10 31 30 1 30" style={{ fill: "none", stroke: "#000", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2px" }} />
					</g>
				</svg>
			</span>
		</button>
	);
};

export const ActionsButtonGroup = ({ primaryBtnText, secondaryBtnText, onPrimaryBtnClick, onSecondaryBtnClick }: ActionsBtnGroupProps) => {
	return (
		<div>
			<SecondaryButton text={secondaryBtnText} onClick={onSecondaryBtnClick} />
			<PrimaryButton text={primaryBtnText} onClick={onPrimaryBtnClick} />
		</div>
	);
};
