import React, { useState, useEffect, useRef, MutableRefObject } from 'react'
import HomeSlide from './HomeSlide'
import ObjectivesSlide from './ObjectivesSlide'
import PricingSlide from './PricingSlide'

// import { NextSlideButton, PrevSlideButton } from '../Buttons'
import { debounce, throttle } from '../utils'


type Props = {
    pathname: string,
}

const useSlideScrollHalt = (refMap:any[])=>{
    let currentScrollY = window.scrollY;
    const handleScroll = (e:any)=>{
        if(currentSlide<0) {
            currentSlide = 0;
        }
        if(currentSlide > 2){
            currentSlide = 2;
        }
        console.log("CALLED ", currentScrollY, ", AND ", window.scrollY)
        if(currentScrollY>window.scrollY){
            console.log("SCROLLING UP")
            let ref = refMap[--currentSlide];
            if(ref && ref.current){
                (ref.current! as {scrollIntoView:Function}).scrollIntoView({behavior:"smooth"});
            }
        }
        else if(currentScrollY<window.scrollY){
            console.log("SCROLLING DOWN")
            let ref = refMap[++currentSlide];
            if(ref && ref.current){
                (ref.current! as {scrollIntoView:Function}).scrollIntoView({behavior:"smooth"});
            }
        }
    }
    const updateCurrentScrollPos = ()=>{
        currentScrollY = window.scrollY;
    }
    const debouncedScrollHandler = debounce(handleScroll, 100);
    const throttledScrollUpdateCb = throttle(updateCurrentScrollPos, 200);

    useEffect(() => {
        document.addEventListener("wheel", debouncedScrollHandler)
        document.addEventListener("scroll", throttledScrollUpdateCb)
        return () => {
            document.removeEventListener("wheel", debouncedScrollHandler);
            document.removeEventListener("scroll", throttledScrollUpdateCb);
        }
    }, [])
}


let currentSlide = 0;
export default ({pathname}:Props)=>{
    const homeRef = useRef()
    const objectivesRef = useRef()
    const pricingRef = useRef()

    const refMap = [homeRef, objectivesRef, pricingRef];

    useSlideScrollHalt(refMap);

    return (
        <div className="SlideManager">
            <HomeSlide isActive={true} slideRef={homeRef} pathname={pathname} />
            <ObjectivesSlide isActive={true} slideRef={objectivesRef} pathname={pathname} />
            <PricingSlide isActive={true} slideRef={pricingRef}  pathname={pathname}/>
            {/* <div className="actions">
             {currentSlide < 2 && //show next slide button if currentSlide is not the last slide
             <NextSlideButton onClick={()=>setCurrentSlide(currentSlide+1)} />
             }
             {currentSlide > 0 &&  //show previous button if current slide is not the first slide
             <PrevSlideButton onClick={()=>setCurrentSlide(currentSlide-1)} />
             }
             </div> */}
        </div>
    )
}