import React, { useState } from 'react'
import logo from "./assets/logo.svg";
import logoMonizze from "./assets/monizze_logo.png";
import {LoginButton, BurgerMenuButton, SecondaryButton} from './Buttons';
import LanguageSelect from "../_other/LanguageSelect";

import i18n from "i18next";
import { Trans } from 'react-i18next';

type Props = {
    pathname?: string,
}

export const MobileMenuList = ({onItemClick}:{onItemClick:(item:string)=>void})=>{
    return (
        <div className="mobile-menu-container">
            <div className="menu-item" onClick={()=> onItemClick("login")}>
                <Trans>
                    login
                </Trans>
            </div>

            <div className="menu-item" onClick={()=> onItemClick("form")}>
                <Trans>
                    landing_customer_button
                </Trans>
            </div>

        </div>
    )
};

export default ({pathname}:Props) => {
    const [showBurgerMenu, setShowBurgerMenu] = useState(false);

    console.log(pathname);

    return (
        <>
            <header className="header">
            <div style={{display:"flex", alignItems:"center", flexWrap:"wrap"}}>
                <div className="brand">
                    <img src={logo} />
                    <h2>QRsafe.be</h2>
                </div>


                {pathname == "/monizze" &&
                    <>
                        <div style={{flexBasis: "100%",height: "0"}}></div>
                        <img width='200px' src={logoMonizze} />
                    </>
                }
            </div>


            <div style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
                <div>
                    <div className="desktop-menu">
                        {pathname != "/monizze" &&
                            <SecondaryButton text={i18n.t("landing_customer_button")} onClick={onFormClick} />
                        }

                        <LoginButton onClick={onLoginClick} />
                    </div>
                    <div className="mobile-menu" style={{marginRight:'5px'}}>
                        <BurgerMenuButton isActive={showBurgerMenu} onClick={()=>setShowBurgerMenu(!showBurgerMenu)} />
                    </div>
                </div>

                <div>
                    <LanguageSelect
                        onLanguageSelected={onLanguageSelected}
                        selectedLanguage={i18n.language}
                    />
                </div>
            </div>


            </header>
            {/* We'll need to properly implement the onItemClicked for a particular item*/}
            {showBurgerMenu &&
                <MobileMenuList onItemClick={(menuItem)=>onMobileMenuItemClick(menuItem)}/>
            }
        </>
    );

    function onLanguageSelected(LanguageName) {
        localStorage.setItem('language',LanguageName);
        i18n.changeLanguage(LanguageName);

        console.log(pathname);
        // window.location.reload();
        window.location.href = pathname;
    }

    function onLoginClick(){
        window.location.href = "login";
    }

    function onFormClick(){
        window.location.href = "form";
    }

    function onMobileMenuItemClick(item){
        setShowBurgerMenu(false);
        if(item=="login"){
            onLoginClick();
        } else if(item=="form") {
            onFormClick();
        }
    }
}