import React from 'react';

import { serverService } from '../../_services/server.service';
import { toast } from "react-toastify";
import { Row,Col, Form, Button } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import {emphasize} from "@material-ui/core";

let sha1 = require('sha1');
const qs = require('query-string');


interface MyProps {
}

interface MyState {
    submitted: boolean,
    loading: boolean,
    error: string,
    formFields:any,
}

class RegisterBetaPage extends React.Component<MyProps & RouteComponentProps,MyState> {
    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',
            formFields:{},
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }


    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }


    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });

        const { formFields } = this.state;
        const Username = formFields.Username;
        const Password = formFields.Password;
        const CompanyName = formFields.CompanyName;


        // stop here if form is invalid
        if (!(Username) || !(Password) || !(CompanyName)) {
            toast("Missing fields");
            return;
        }

        let user = {'Username': Username,'Password': sha1(Password),'CompanyName': CompanyName};

        this.setState({ loading: true });
        serverService.insertUser(user)
                .then(
                response => {
                    console.log(response);
                    this.setState({ submitted:false, loading: false, formFields: {Username:"", Password:"", CompanyName:""} });
                    if(response.status == 0){
                        toast.warn(response.message);
                    } else {
                        toast.success(response.message);
                    }
                }, error => {
                    console.log(error);
                    toast.error("Server error");
                    this.setState({ loading: false })
                }
            );
    }


    render() {
        const { formFields,submitted, loading, error} = this.state;

        const Username = formFields.Username;
        const Password = formFields.Password;
        const CompanyName = formFields.CompanyName;


        return (
            <div className="col-md-8 col-md-offset-3 text-center mx-auto" id="loginContainer">
                <br/>
                <br/>
                <h2 style={{color:'#0072b4'}}>Register restaurant</h2>
                <br/>

                <form name="form" onSubmit={this.handleSubmit}>

                    <div>
                        <h4 style={{textAlign:'left'}}>1. User details</h4>
                        <br/>
                        <div className={'form-group' + (submitted && !Username ? ' has-error' : '')}>
                            <input type="text" className="form-control login-field" name="Username" value={Username} placeholder="*Username" onChange={this.handleChange} />
                            {submitted && !Username &&
                            <div className="help-block">Username is mandatory</div>
                            }
                        </div>

                        <div className={'form-group' + (submitted && !Password ? ' has-error' : '')}>
                            <input type="text" className="form-control login-field" name="Password" value={Password} placeholder="*Password" onChange={this.handleChange} />
                            {submitted && !Password &&
                            <div className="help-block">Password is mandatory</div>
                            }
                        </div>

                        <div className={'form-group' + (submitted && !CompanyName ? ' has-error' : '')}>
                            <input type="text" className="form-control login-field" name="CompanyName" value={CompanyName} placeholder="*CompanyName" onChange={this.handleChange} />
                            {submitted && !CompanyName &&
                            <div className="help-block">CompanyName is mandatory</div>
                            }
                        </div>

                    <br/>

                    </div>

                    <div className="form-group">
                        <button className="btn-login" disabled={loading}>Register</button>
                        {loading &&
                        <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        }
                    </div>

                </form>

                {error &&
                    <div className={'alert alert-danger'}>{error}</div>
                }

            </div>

        );
    }


}

export { RegisterBetaPage };