import React from 'react'
import i18n from "i18next";
import { Trans } from 'react-i18next';

export default ()=>{

    let privacyPolicyLink = "/assets/pdfs/privacy policy_en.pdf";
    if(i18n.language == "fr"){
        privacyPolicyLink = "/assets/pdfs/privacy policy_fr.pdf";
    } else if(i18n.language == "nl"){
        privacyPolicyLink = "/assets/pdfs/privacy policy_nl.pdf";
    }


    return (
        <footer className="footer">
            <div>
                <a className="privacy-policy" href={privacyPolicyLink}>
                    <Trans>
                        landing_privacy
                    </Trans>
                </a>
            </div>

            <div style={{float:'right', marginRight:'20px'}}>
                <a className="privacy-policy" href="/support" >
                    <Trans>
                        contact_us
                    </Trans>
                </a>
            </div>

            <br />
            <a className="copyright">QRsafe.be © 2020</a>
        </footer>
    )
}