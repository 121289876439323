export function noAuthHeader() {
    return {
        'Content-Type':'application/json',
        'keyAuthorization': "6gegeo4tn34t3t9y4gCNEV3yk53y3FE",
    };
}
export function authHeader() {
    // return authorization header with basic auth credentials
    let user = JSON.parse(localStorage.getItem('user'));

    if (user['AuthToken'].length > 0) {
        return { 'tAuthorization': user['AuthToken'],
            'Content-Type':'application/json',
        };
    } else {
        return {};
    }
}


export function authHeaderFile() {
    // return authorization header with basic auth credentials
    let user = JSON.parse(localStorage.getItem('user'));

    if (user['AuthToken'].length > 0) {
        return {'tAuthorization': user['AuthToken'],
        };
    } else {
        return {};
    }
}