import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Route, withRouter, Switch, Redirect, RouteProps } from 'react-router-dom';
import { PrivateRoute } from '../_components/PrivateRoute';
import { LoginPage } from '../ui/LoginPage/LoginPage';
import { RegisterBetaPage } from '../ui/RegisterBetaPage/RegisterBetaPage';
import { RegisterPage } from '../ui/RegisterPage/RegisterPage';
import { FormPage } from '../ui/FormPage/FormPage';
import { HomePage } from '../ui/HomePage/HomePage';
import LandingPage from "../ui/LandingPage/LandingPage";
import SettingsPage from '../ui/SettingsPage/SettingsPage';
import { NavDropdown } from "react-bootstrap";
import './App.scss'
import LanguageSelect from "../ui/_other/LanguageSelect";
import i18n from "i18next";
import { Trans } from 'react-i18next';

import PubSub from '../_helpers/PubSub.js'
import {ForgotPasswordPage} from "../ui/ForgotPassword/ForgotPasswordPage";
import {ChangePasswordPage} from "../ui/ForgotPassword/ChangePasswordPage";
import {DebugPage} from "../ui/DebugPage/DebugPage";
import SupportPage from "../ui/SupportPage/SupportPage";

const qs = require('query-string');


type MyState = {
    user: string,
    selectedLanguage: string,
};

class App extends React.Component<RouteProps, MyState> {

    constructor(props) {
        super(props);

        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            selectedLanguage: 'en'
        };

        this.onLanguageSelected = this.onLanguageSelected.bind(this);
        this.onLogout = this.onLogout.bind(this);

    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({ user: JSON.parse(localStorage.getItem("user")) });
        }
    }

    componentDidMount() {
        let LanguageFromUrl = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).l;
        if(LanguageFromUrl && LanguageFromUrl.length > 0
            && (LanguageFromUrl == "en" || LanguageFromUrl == "fr" || LanguageFromUrl == "nl")){
            i18n.changeLanguage(LanguageFromUrl);
        }

        this.setState({ selectedLanguage: i18n.language });
    }

    render() {
        let { user, selectedLanguage } = this.state;

        let topHeaderLabel = "";
        if(user){
            topHeaderLabel = i18n.t("connected_as")  + " " + user['Username'];
        }

        let privacyPolicyLink = "/assets/pdfs/privacy policy_en.pdf";
        let generalConditionsHorecaLink = "/assets/pdfs/Conditions generales_horeca_en.pdf";
        if(i18n.language == "fr"){
            privacyPolicyLink = "/assets/pdfs/privacy policy_fr.pdf";
            generalConditionsHorecaLink = "/assets/pdfs/Conditions generales_horeca_fr.pdf";
        } else if(i18n.language == "nl"){
            privacyPolicyLink = "/assets/pdfs/privacy policy_nl.pdf";
            generalConditionsHorecaLink = "/assets/pdfs/Conditions generales_horeca_nl.pdf";
        }



        return (
            <>
                <div className="page-wrapper">
                    <div className="container">
                        <nav className="main-nav">
                            <div className="row align-items-center">
                                <div className="col">
                                    <a href="/home">
                                        <div className="brand">
                                            <img src="/assets/images/logo.svg" alt="Logo" />
                                            QRsafe.be
                                        </div>
                                    </a>
                                </div>
                                <div className="col text-right">
                                    {user &&
                                        <NavDropdown title={topHeaderLabel} id="basic-nav-dropdown">
                                            <NavDropdown.Item onClick={this.onLogout}>
                                                <Trans>
                                                    logout
                                                </Trans>
                                            </NavDropdown.Item>
                                        </NavDropdown>
                                    }

                                    <img src="/assets/images/globe.svg" alt="Globe" className="d-none d-md-inline" />
                                    <LanguageSelect
                                        onLanguageSelected={this.onLanguageSelected}
                                        selectedLanguage={selectedLanguage}
                                    />
                                </div>
                            </div>
                        </nav>

                        <ToastContainer
                            autoClose={3000}
                            hideProgressBar={true}
                        />

                        {/*{*/}
                            {/*user &&*/}
                            {/*<>*/}
                            {/*<MyNavbar user={this.state.user} style={{ clear: 'right' }} />*/}
                            {/*<div className="row position-relative">*/}
                                {/*<div className="col-md-2 sidebar-wrapper">*/}
                                    {/*<SideNavbar user={this.state.user} />*/}
                                {/*</div>*/}
                                {/*<div className="col-md">*/}

                                {/*</div>*/}
                            {/*</div>*/}
                            {/*</>*/}
                        {/*}*/}

                        <Switch>
                            <Route path="/hello" component={LandingPage} />
                            <Route path="/monizze" component={LandingPage} />
                            <Route path="/login" component={LoginPage} />
                            <Route path="/registerbeta" component={RegisterBetaPage} />
                            <Route path="/register" component={RegisterPage} />

                            <Route path="/form" component={FormPage} />

                            <Route path="/forgotpassword" component={ForgotPasswordPage} />
                            <Route path="/resetpassword" component={ChangePasswordPage} />


                            <Route exact path="/support" component={SupportPage} />

                            <PrivateRoute exact path="/home" component={HomePage} />
                            <PrivateRoute exact path="/settings" component={SettingsPage} />


                            <PrivateRoute exact path="/stresstest" component={DebugPage} />

                            <Redirect from='*' to='/hello' />
                        </Switch>
                    </div>
                </div>
            
                {this.props.location.pathname != "/support" &&
                    <footer>
                        <div className="row">
                            <div className="col-md">
                                <a href={privacyPolicyLink} target="_blank">
                                    <Trans>
                                        privacy_policy
                                    </Trans>
                                </a>

                                &nbsp;&nbsp;&nbsp;
                                <a href={generalConditionsHorecaLink} target="_blank">
                                    <Trans>
                                        tos_horeca
                                    </Trans>
                                </a>

                                <br />
                                <Trans>
                                    all_rights_reserved
                                </Trans>
                                , QRsafe.be (c) 2020
                            </div>
                        </div>
                    </footer>
                }

            </>
        );
    }

    onLanguageSelected(LanguageName) {
        localStorage.setItem('language',LanguageName);
        i18n.changeLanguage(LanguageName);
        this.forceUpdate();

        //qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).l;

        // console.log(this.props.location);

        PubSub.publish('languageChanged',LanguageName);
    }

    onLogout(){
        localStorage.removeItem('user');
        window.location.href = "login";
        // this.props.history.push("/login");
    }

}

export default withRouter(props => <App {...props} />);