import React from 'react'
import Slide, { SlideContent } from './Slide'
import {ActionsButtonGroup, PrimaryButton, SecondaryButton} from '../Buttons';
import Footer from '../Footer';

import i18n from "i18next";
import { Trans } from 'react-i18next';


type Props = {
    isActive: boolean,
    slideRef: any,
    pathname: string,
}

export default ({isActive, slideRef, pathname}:Props)=>{
    return (
        <Slide className="ObjectivesSlide" isActive={isActive} slideRef={slideRef}>
            <SlideContent>
            <div>
                <h2>
                    <Trans>
                        landing_why
                    </Trans>
                </h2>
                <div className="item">
                    <CallIcon />
                    <p className="description">
                        <Trans>
                            landing_phone
                        </Trans>
                    </p>
                </div>
                <div className="item">
                    <CallendarIcon />
                    <p className="description">
                        <Trans>
                            landing_agenda
                        </Trans>
                    </p>
                </div>
                <div className="item">
                    <CollectionIcon />
                    <p className="description">
                        <Trans>
                            landing_data
                        </Trans>
                    </p>
                </div>
                </div>
                <div className="solution">
                    <p>
                        <Trans>
                            landing_offer
                        </Trans>
                    </p>
                    <div>
                        {pathname != "/monizze" &&
                            <SecondaryButton text={i18n.t("landing_customer_button")} onClick={onFormClick} />
                        }

                        <PrimaryButton text={i18n.t('landing_horeca_button')} onClick={onRegisterButtonClick} />

                    </div>
                </div>
            </SlideContent>
            <Footer />
        </Slide>
    )
}

function onRegisterButtonClick(){
    window.location.href = "register";
}

function onFormClick(){
    window.location.href = "form";
}

const CallIcon = ()=>{
    return (
        <svg fill="none" height="64" stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="64" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/>
        </svg>
    )
}

const CallendarIcon = ()=>{
    return (
        <svg fill="whitesmoke" enableBackground="new 0 0 24 24" height="64px" id="Layer_1" version="1.1" viewBox="0 0 24 24" width="64px" xmlSpace="preserve" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g>
                <path d="M22.9,1H18c0-0.6-0.4-1-1-1s-1,0.4-1,1H8c0-0.6-0.4-1-1-1S6,0.4,6,1H1.1C0.5,1,0,1.5,0,2.1v20.8C0,23.5,0.5,24,1.1,24h21.8   c0.6,0,1.1-0.5,1.1-1.1V2.1C24,1.5,23.5,1,22.9,1z M22,22H2V8h20V22z M22,7H2V3h4v1c0,0.6,0.4,1,1,1s1-0.4,1-1V3h8v1   c0,0.6,0.4,1,1,1s1-0.4,1-1V3h4V7z"/>
                <path d="M10.3,17.7c0.4,0.4,1,0.4,1.4,0l4.1-4.1c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0L11,15.7l-1.4-1.4c-0.4-0.4-1-0.4-1.4,0   c-0.4,0.4-0.4,1,0,1.4L10.3,17.7z"/>
            </g>
        </svg>
    )
}

const CollectionIcon = ()=>{
    return (
        <svg height="64px" version="1.1" viewBox="0 0 24 24" width="64px"  xmlnsXlink="http://www.w3.org/1999/xlink">
            <title/><desc/><defs/>
            <g fill="none" fill-rule="evenodd" id="miu" stroke="none" stroke-width="1">
                <g id="Artboard-1" transform="translate(-791.000000, -371.000000)">
                    <g id="slice" transform="translate(215.000000, 119.000000)"/>
                    <path d="M792,373.002462 L792,391.997538 C792,392.551183 792.450975,393 792.990778,393 L796.009222,393 C796.556414,393 797,392.560542 797,391.997538 L797,373.002462 C797,372.448817 796.549025,372 796.009222,372 L792.990778,372 C792.443586,372 792,372.439458 792,373.002462 Z M796,373.00204 C795.991456,373.00204 795.993094,391.997769 795.993094,391.997769 C795.993094,392.005994 792.990778,392.000108 792.990778,392.000108 C792.999322,392.000108 792.997684,373.004379 792.997684,373.004379 C792.997684,372.996154 796,373.00204 796,373.00204 Z M798,373.002462 L798,391.997538 C798,392.551183 798.450975,393 798.990778,393 L802.009222,393 C802.556414,393 803,392.560542 803,391.997538 L803,373.002462 C803,372.448817 802.549025,372 802.009222,372 L798.990778,372 C798.443586,372 798,372.439458 798,373.002462 Z M802,373.00204 C801.991456,373.00204 801.993094,391.997769 801.993094,391.997769 C801.993094,392.005994 798.990778,392.000108 798.990778,392.000108 C798.999322,392.000108 798.997684,373.004379 798.997684,373.004379 C798.997684,372.996154 802,373.00204 802,373.00204 Z M803.627042,373.97313 L808.543329,392.320965 C808.686623,392.855744 809.238394,393.172548 809.759803,393.032837 L812.675397,392.251606 C813.203943,392.109982 813.518674,391.570689 813.372958,391.02687 L808.456671,372.679035 C808.313377,372.144256 807.761606,371.827452 807.240197,371.967163 L804.324603,372.748394 C803.796057,372.890018 803.481326,373.429311 803.627042,373.97313 Z M807.490636,372.937447 C807.482383,372.939658 812.400422,391.287699 812.400422,391.287699 C812.40255,391.295644 809.501012,392.067015 809.501012,392.067015 C809.509265,392.064803 804.591226,373.716762 804.591226,373.716762 C804.589098,373.708817 807.490636,372.937447 807.490636,372.937447 Z" fill="whitesmoke" id="editor-books-library-collection-outline-stroke"/>
                </g>
            </g>
        </svg>
    )
}