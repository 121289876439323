import React from 'react';

import { serverService } from '../../_services/server.service';
import { toast } from "react-toastify";
import { Row,Col,Button } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";

import { Trans } from 'react-i18next';

let sha1 = require('sha1');

interface MyProps {
}

interface MyState {
    submitted: boolean,
    loading: boolean,
    error: string,
}


class SettingsPage extends React.Component<MyProps & RouteComponentProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',
        };

        this.handleManageBillingClick = this.handleManageBillingClick.bind(this);

    }


    render() {
        return (
            <div>
                <br/>

                <div className="col-md-6 col-md-offset-3 text-center mx-auto" id="loginContainer">

                    <Button variant="primary" onClick={this.handleManageBillingClick}>
                        <Trans>
                            manage_billing
                        </Trans>
                    </Button>

                </div>

                <br/>
                <br/>
                <br/>
                <br/>
                <br/>

            </div>
        );
    }

    handleManageBillingClick () {
        serverService.createCustomerPortalSession()
            .then(response => {
                if(response.status == "0"){
                    toast.warn(response.message);
                } else {
                    window.location.href = response.data;
                }
            },
            error => {
                console.log(error);
            });
    }


}


export default SettingsPage;