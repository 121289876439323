/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { CirclePicker } from 'react-color'
import {Trans} from 'react-i18next';


interface MyProps {
    onConfirmNo(): any,
    onConfirmYes(obj:any): any,
    show:boolean,
    onHide(): any,
}

interface MyState {
    loading:boolean,
    formFields:any,
}


class ModalColorPicker extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            formFields:{},
        };

        this.onClickYes = this.onClickYes.bind(this);
        this.handleColorChange = this.handleColorChange.bind(this);
    }


    render() {
        let {loading,formFields} = this.state;

        return (
            <Modal
                {...this.props}
                dialogClassName="modal-90w confimDialog"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <Trans>
                            pick_your_favorite_color
                        </Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CirclePicker
                        color={ formFields.color }
                        colors={['#0072b4','#287a2b','#EEEEEE','#4d4d4d','#f49100','#d15160']}
                        onChange={ this.handleColorChange } />
                </Modal.Body>

                <Modal.Footer>
                    <br/>

                    <Button variant="secondary" onClick={this.props.onConfirmNo}>
                        <Trans>
                            cancel
                        </Trans>
                    </Button>

                    <Button id="proceed_btn" variant="danger" onClick={this.onClickYes}>
                        <Trans>
                           generate_and_download
                        </Trans>
                    </Button>

                    {loading &&
                    <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                </Modal.Footer>
            </Modal>
        );
    }


    handleColorChange(color, event) {
        let formFields = this.state.formFields;
        formFields['color'] = String(color.hex);
        this.setState({ formFields: formFields });
    }

    onClickYes() {
        let formFields = this.state.formFields;
        if(formFields.color == undefined){
            formFields.color = "#0072b4";
        }
        this.props.onConfirmYes(formFields);
    }

}

export default ModalColorPicker;