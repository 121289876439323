import React, {ReactNode} from 'react';
import "./Slide.css";

type Props = {
    children: ReactNode,
    isActive: boolean,
    className?:string,
    slideRef: any
}

export const SlideContent = ({children}:{children:ReactNode})=>{
    return (
        <div className="SlideContent">
            {children}
        </div>
    )
};

export default ({children, isActive, className, slideRef}:Props)=>{
    return (
        <section ref={slideRef} className={`Slide ${className||""} ${isActive?"active":""}`}>
            {children}
        </section>
    )
}