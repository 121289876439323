import {authHeader, authHeaderFile, noAuthHeader} from '../_helpers/AuthHeader';

import {config} from '../_helpers/C'

export const serverService = {
    login,
    forgotPassword,
    logout,
    getAnswers,
    insertAnswer,

    getNameById,
    insertUser,
    updateUser,
    updatePassword,

    generatePdf,

    autoRegisterUser,

    createCustomerPortalSession,

    checkIfUsernameAlreadyExists,

    sendMailToSupport,
};

const REACT_APP_SERVER_API_URL = config.API_BASE_URL;

function login(email, password) {
    const requestOptions = {
        method: 'GET',
        headers: noAuthHeader(),
    };

    return fetch(REACT_APP_SERVER_API_URL + `/public/auth`+"?Username="+email+"&Password="+password, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function forgotPassword(Username, language) {
    const requestOptions = {
        method: 'GET',
        headers: noAuthHeader(),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/public/auth/forgot_password` + "?Username=" + Username + "&language=" + language, requestOptions)
        .then(handleResponse);
}

function logout() {
    localStorage.removeItem('user');
}


function getAnswers() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/answers`, requestOptions)
        .then(handleResponse);
}

function generatePdf(colorStr,language) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let colorStrWithoutFirstCharacter = colorStr.substr(1);

    return fetch(REACT_APP_SERVER_API_URL + "/private/generate_pdf?color=" + colorStrWithoutFirstCharacter + "&language=" + language, requestOptions)
        .then(handleResponse);
}

function insertAnswer(answer, IdUser) {
    const formObj = {"data": answer, "IdUser": IdUser};

    const requestOptions = {
        method: 'POST',
        headers: noAuthHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/public/answers`, requestOptions)
        .then(handleResponse);
}


function getNameById(IdUser) {
    const requestOptions = {
        method: 'GET',
        headers: noAuthHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/public/users?IdUser=` + IdUser, requestOptions)
        .then(handleResponse);
}

function insertUser(user) {
    const formObj = {"data": user};

    const requestOptions = {
        method: 'POST',
        headers: noAuthHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/public/register_user`, requestOptions)
        .then(handleResponse);
}

function autoRegisterUser(formFields,language) {
    const formObj = {"data": formFields, "language": language};

    const requestOptions = {
        method: 'POST',
        headers: noAuthHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/public/autoregister`, requestOptions)
        .then(handleResponse);
}


function createCustomerPortalSession() {
    const formObj = {"data": {}};

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/create_customer_portal_session`, requestOptions)
        .then(handleResponse);
}


function updateUser(user) {
    const formObj = {"data": user};

    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/users`, requestOptions)
        .then(handleResponse);
}


function updatePassword(CompanyName, IdUser, Password, h) {
    const formObj = {"CompanyName": CompanyName, "IdUser":IdUser, "Password":Password, "h": h};

    const requestOptions = {
        method: 'POST',
        headers: noAuthHeader(),
        body: JSON.stringify(formObj),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/public/users/update_password`, requestOptions)
        .then(handleResponse);
}



function checkIfUsernameAlreadyExists(Username) {
    const requestOptions = {
        method: 'GET',
        headers: noAuthHeader(),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/public/users/check_if_user_exists` + "?Username=" + Username, requestOptions)
        .then(handleResponse);
}


function sendMailToSupport(fieldValues) {

    const requestOptions = {
        method: 'POST',
        headers: noAuthHeader(),
        body: JSON.stringify(fieldValues),
    };
    return fetch(REACT_APP_SERVER_API_URL + `/public/mail/contact_support`, requestOptions)
        .then(handleResponse);
}


function handleResponse(response) {
    return response.text().then(text => {
        let data;
        try {
            data = text && JSON.parse(text);

            if (data.status === "0" && data.message.includes("Unauthorized")) {
                // auto logout if 401 response returned from api
                logout();
                window.location.href = "/login";
            }

            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    logout();
                    window.location.href = "/login";
                }

                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        } catch (error){
            return false
        }
    });
}