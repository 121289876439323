import React from 'react'
import Slide, { SlideContent } from './Slide'
import { PrimaryButton } from '../Buttons'
import Footer from '../Footer'

import i18n from "i18next";
import { Trans } from 'react-i18next';

type Props = {
    isActive: boolean,
    slideRef: any,
    pathname: string,
}

export default ({isActive, slideRef}:Props)=>{
    return (
        <Slide className="PricingSlide" isActive={isActive} slideRef={slideRef}>
            <SlideContent>
                <div>
                    <h2>
                        <Trans>
                            landing_pricing_title
                        </Trans>
                    </h2>
                    <div className="desc-container">
                        <div className="paragh"> 
                            <span className="price">
                                 <Trans>
                                    landing_pricing_number
                                </Trans>
                            </span>
                            <Trans>
                                landing_pricing_1line
                            </Trans>
                        </div>
                        <div className="paragh">
                            <Trans>
                                landing_pricing_2line
                            </Trans>
                        {/*All users accepting meal vouchers from Monizze are given a permanent*/}
                {/*coupon code of <b style={{fontSize:20}}>50%</b> which is <b style={{fontSize:20}}>12,5€</b> excl VAT / month. (until the end of the*/}
                {/*month).*/}
                        </div>
                        <div className="paragh">
                            <Trans>
                                landing_pricing_3line
                            </Trans>
                        </div>
                    </div>
                    <div>
                        <PrimaryButton text={i18n.t('landing_horeca_button')} onClick={onRegisterClick}/>
                    </div>
                </div>
            </SlideContent>
            <Footer />
        </Slide>
    )

    function onRegisterClick(){
        window.location.href = "register";
    }
}