import React from 'react';

import { serverService } from '../../_services/server.service';
import { toast } from "react-toastify";
import { Row, Col } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { Trans } from 'react-i18next';

import i18n from "i18next";

import ModalConfirmTOS from "./ModalConfirmTOS";

import TextField from '@material-ui/core/TextField';

let sha1 = require('sha1');

interface MyProps {
}

interface MyState {
    submitted: boolean,
    loading: boolean,
    error: string,
    formFields: any,
    modalConfirmTOShow: boolean,
}

class LoginPage extends React.Component<MyProps & RouteComponentProps, MyState> {

    private tempLoggedInUser;

    constructor(props) {
        super(props);

        localStorage.removeItem('user');

        this.state = {
            submitted: false,
            loading: false,
            error: '',
            formFields: [],
            modalConfirmTOShow: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.modalConfirmTOSClose = this.modalConfirmTOSClose.bind(this);
        this.doProceedWithLogin = this.doProceedWithLogin.bind(this);
        this.modalConfirmTOSYes = this.modalConfirmTOSYes.bind(this);

    }


    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });


        const { formFields } = this.state;
        const username = formFields.username;
        const password = formFields.password;

        // stop here if form is invalid
        if (!(username && password)) {
            return;
        }

        this.setState({ loading: true });
        serverService.login(username, sha1(password))
            .then(
                response => {
                    this.setState({ loading: false });
                    if (response.status == 0) {
                        toast.warn(response.message);
                    } else {
                        if (response.data == undefined || response.data.length == 0) {
                            this.setState({ error: "Server errror" });
                        } else {
                            toast.success(i18n.t("msg_successfully_logged_in"));
                            this.tempLoggedInUser = response.data[0];

                            // console.log(this.tempLoggedInUser);

                            if (this.tempLoggedInUser.AcceptedTOS == 0) {
                                // this.setState({ modalConfirmTOShow: true });
                                this.doProceedWithLogin();
                            } else {
                                this.doProceedWithLogin();
                            }

                        }
                    }
                },
                error => {
                    console.log(error);
                    toast.error("Server error");
                    this.setState({ loading: false })
                }
            );
    }

    render() {
        const { formFields, submitted, loading, error } = this.state;
        const username = formFields.username;
        const password = formFields.password;

        return (
            <div>
                <div className="col-md-6 col-md-offset-3 text-center mx-auto" id="loginContainer">
                    <h2 className="text-signin">
                        <Trans>
                            Log in
                        </Trans>
                    </h2>
                    <br />
                    <form name="form" className={`text-center`} onSubmit={this.handleSubmit}>
                        <div className={'form-group mb-4' + (submitted && !username ? ' has-error' : '')}>
                            <Trans>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    name="username"
                                    value={username}
                                    label={i18n.t("your_login")}
                                    placeholder={i18n.t("your_login")}
                                    onChange={this.handleChange}
                                    className="form-field"
                                />
                            </Trans>


                            {submitted && !username &&
                                <div className="help-block">
                                    <Trans>
                                        username_is_mandatory
                                </Trans>
                                </div>
                            }
                        </div>
                        <div className={'form-group mb-0' + (submitted && !password ? ' has-error' : '')}>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                name="password"
                                value={password}
                                label={i18n.t("password")}
                                placeholder={i18n.t("password")}
                                onChange={this.handleChange}
                                className="form-field"
                                type="password"
                            />

                            {submitted && !password &&
                                <div className="help-block">
                                    <Trans>
                                        password_is_mandatory
                                </Trans>
                                </div>
                            }
                        </div>
                        <br />
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <button className="landing-button has-bg mt-2 btn-login" disabled={loading}>
                                        <Trans>
                                            login
                                        </Trans>
                                    </button>
                                    {
                                        loading &&
                                        <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    }
                                </div>
                            </Col>
                        </Row>

                        {
                            error &&
                            <div className={'alert alert-danger'}>{error}</div>
                        }
                    </form>
                </div>

                <div className="row mt-4">
                    <div className="col text-center">
                        <span className="text-light">
                            <Trans>
                                or_you_can
                            </Trans>
                        </span>
                        <br />
                        <a href="/register">
                            <Trans>
                                register
                            </Trans>
                        </a>
                        &nbsp;&nbsp;
                        /
                        &nbsp;&nbsp;
                        <a href="/forgotpassword">
                            <Trans>
                                forgot_password
                            </Trans>
                        </a>
                    </div>
                </div>


                <ModalConfirmTOS
                    show={this.state.modalConfirmTOShow}
                    onConfirmNo={this.modalConfirmTOSClose}
                    onHide={this.modalConfirmTOSClose}
                    onConfirmYes={this.modalConfirmTOSYes}
                    size="lg"
                />

            </div>

        );
    }

    modalConfirmTOSYes() {
        localStorage.setItem('user', JSON.stringify(this.tempLoggedInUser));

        let tempUserToUpdate = { IdUser: this.tempLoggedInUser['IdUser'], AcceptedTOS: '1', };
        serverService.updateUser(tempUserToUpdate)
            .then(val => {
                this.doProceedWithLogin();
            }, error => {
                console.log(error);
            });
    }

    doProceedWithLogin() {
        localStorage.setItem('user', JSON.stringify(this.tempLoggedInUser));

        let { from } = { from: { pathname: "/home" } };
        this.props.history.push(from);
    }

    modalConfirmTOSClose() {
        this.setState({ modalConfirmTOShow: false });
    }

}


export { LoginPage };