import React from 'react';

import { serverService } from '../../_services/server.service';
import { toast } from "react-toastify";
import { Row, Col } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";

import SlidesManager from './slides/SlidesManager';

import { Trans } from 'react-i18next';

let sha1 = require('sha1');

interface MyProps {
}

interface MyState {
    submitted: boolean,
    loading: boolean,
    error: string,
}


class LandingPage extends React.Component<MyProps & RouteComponentProps, MyState> {
    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',
        };
    }


    render() {

        let pathname = this.props.location.pathname;

        return (
            <div className="App">
                <SlidesManager pathname={pathname} />
            </div>
        );
    }


}


export default LandingPage;