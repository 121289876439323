/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";
import {Trans} from "react-i18next";
import i18n from "i18next";

interface MyProps {
    onConfirmNo?(): any,
    onConfirmYes?(): any,
    show:boolean,
    onHide?(): any,
    size?:any,
}

interface MyState {
}


class ModalThankYou extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);
        this.state = {
        };

    }

    render() {
        return (
            <Modal
                {...this.props}
                dialogClassName="modal-90w confimDialog"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <Trans>
                            thank_you
                        </Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Trans>
                        thank_you_description
                    </Trans>
                </Modal.Body>
            </Modal>
        );
    }


}

export default ModalThankYou;