import React from 'react';

import { serverService } from '../../_services/server.service';
import { toast } from "react-toastify";
import { Row,Col, Form } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import {emphasize} from "@material-ui/core";

import { config } from '../../_helpers/C';

import {Trans} from "react-i18next";
import i18n from "i18next";

import TextField from '@material-ui/core/TextField';
import ModalThankYou from "./ModalThankYou";


let sha1 = require('sha1');
const qs = require('query-string');


interface MyProps {
}

interface MyState {
    submitted: boolean,
    loading: boolean,
    error: string,
    formFields:any,
    siteName:string,
    modalThankYouShow:boolean,
}


class FormPage extends React.Component<MyProps & RouteComponentProps,MyState> {
    private IdUser;
    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',
            formFields:{},
            siteName:"",
            modalThankYouShow:false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.modalThankYouClose = this.modalThankYouClose.bind(this);

        this.fetchNameById = this.fetchNameById.bind(this);

        this.handleFocusChange = this.handleFocusChange.bind(this);
        this.parseParam = this.parseParam.bind(this);

    }


    componentDidMount(){
        this.parseParam();

        if(this.IdUser){
           this.fetchNameById(this.IdUser);
        }

    }


    fetchNameById(IdUser){
        serverService.getNameById(IdUser)
            .then(
                userRes => {
                    if (userRes && userRes.data != undefined) {
                        this.setState({ siteName: userRes.data})
                    }
                },
                error => {
                    console.log(error);
                }
            );
    }

    handleFocusChange(){
        let siteNumber = this.state.formFields.siteNumber;

        if(siteNumber && siteNumber.substr(0,2) == "qr"){
            let IdUser = siteNumber.substr(2,siteNumber.length);
            if(IdUser.length > 0 && isNumber(IdUser)){
                this.fetchNameById(IdUser);
            }
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }

    handleCheckboxChange(e) {
        const { name, checked } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = checked;
        this.setState({ formFields: formFields });
    }

    handleRadioChange(e) {
        const { name, checked, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }


    parseParam(){
        let param = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).c;
        if(param && param.substring(0, 2) == "qr") {
            this.IdUser = param.substring(2, param.length);
        }
    }

    modalThankYouClose(){
        this.setState({ modalThankYouShow: false });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });

        this.parseParam();

        const { formFields } = this.state;
        const siteNumber = formFields.siteNumber;
        const tableNumber = formFields.tableNumber;
        const duration = formFields.duration;
        const firstAndLastName = formFields.firstAndLastName;
        const phoneOrEmail = formFields.phoneOrEmail;

        const PhoneOrEmailRadio = formFields.PhoneOrEmailRadio;


        const acceptPolicy = formFields.acceptPolicy;
        const acceptTos = formFields.acceptTos;

        let isFormValid = true;



        if(!this.IdUser && !(siteNumber)){
            toast.warn(i18n.t("site_number_is_mandatory"));
            isFormValid = false;
        }


        if (!(PhoneOrEmailRadio)) {
            toast.warn(i18n.t("msg_phone_or_email_not_chosen"));
            isFormValid = false;
        }

        if(!(phoneOrEmail)){
            toast.warn(i18n.t("msg_type_in_email"));
            isFormValid = false;
        }

        if(!this.IdUser && siteNumber && siteNumber.substr(0,2) != "qr"){
            toast.warn(i18n.t("msg_site_number_not_valid"));
            isFormValid = false;
        }

        if (tableNumber) {
            if(!isNumber(tableNumber)){
                toast.warn(i18n.t("msg_table_number_should_contain_only_numbers"));
                isFormValid = false;
            }
        }

        if (duration) {
            if(!isNumber(duration)){
                toast.warn(i18n.t("msg_duration_should_contain_only_numbers"));
                isFormValid = false;
            }
        }

        if(firstAndLastName){
            if(!allLetters(firstAndLastName)){
                toast.warn(i18n.t("msg_name_should_contain_only_letters"));
                isFormValid = false;
            }

        }

        if(PhoneOrEmailRadio == "phone"){
            if(!validatePhoneNumber(phoneOrEmail)){
                toast.warn(i18n.t("msg_phone_number_not_valid"));
                isFormValid = false;
            }
        }

        if(PhoneOrEmailRadio == "email"){
            if(!validateEmail(phoneOrEmail)){
                toast.warn(i18n.t("msg_email_not_valid"));
                isFormValid = false;
            }
        }

        if (!(acceptPolicy) || !(acceptTos)) {
            toast.warn(i18n.t("msg_accept_privacy_policy"));
            isFormValid = false;
        }

        if(!isFormValid){
            return;
        }


        let answer = {'TableNumber': tableNumber,'duration': duration,'firstAndLastName': firstAndLastName,
            'Email': phoneOrEmail, "PhoneNumber": phoneOrEmail};

        let IdRestaurant = 0;
        if(!this.IdUser){
            IdRestaurant = siteNumber.substr(2,siteNumber.length);
        } else {
            IdRestaurant = this.IdUser;
        }

        this.setState({ loading: true });
        serverService.insertAnswer(answer, IdRestaurant)
                .then(
                response => {
                    console.log(response);
                    if(response.status == 0){
                        toast.warn(i18n.t("site_not_found"));
                        this.setState({ submitted:false, loading: false});
                    } else {
                        this.setState({ submitted:false, loading: false
                            , formFields: {
                                acceptPolicy:false, phoneOrEmail:"", tableNumber:""
                                ,duration:"", firstAndLastName:"",PhoneOrEmailRadio: ""
                                ,siteNumber:""
                            }
                            ,modalThankYouShow:true
                        });

                        toast.success(i18n.t("thank_you"));
                    }
                }, error => {
                    console.log(error);
                    toast.error("Server error");
                    this.setState({ loading: false })
                }
            );
    }

    render() {
        const { formFields,submitted, loading, error,siteName } = this.state;

        const siteNumber = formFields.siteNumber;
        const tableNumber = formFields.tableNumber;
        const duration = formFields.duration;
        const firstAndLastName = formFields.firstAndLastName;
        const phoneOrEmail = formFields.phoneOrEmail;
        const PhoneOrEmailRadio = formFields.PhoneOrEmailRadio;

        const acceptPolicy = formFields.acceptPolicy;
        const acceptTos = formFields.acceptTos;

        this.parseParam();

        const selectedLanguage = i18n.language;

        let phoneChecked = false;
        let emailChecked = false;
        if(PhoneOrEmailRadio){
            if(PhoneOrEmailRadio == "phone"){
                phoneChecked = true;
            }
            if(PhoneOrEmailRadio == "email"){
                emailChecked = true;
            }
        }


        let generalConditionsLink = "/assets/pdfs/Conditions generales_en.pdf";
        let officalFormLink =  config.API_BASE_URL + "/assets/Formulaire Officiel.pdf";
        let tipsLink = config.API_BASE_URL + "/assets/tipsen.pdf";

        if(i18n.language == "fr"){
            generalConditionsLink = "/assets/pdfs/Conditions generales_fr.pdf";
            officalFormLink =  config.API_BASE_URL + "/assets/Formulaire Officiel.pdf";
            tipsLink = config.API_BASE_URL + "/assets/tipsfr.pdf";
        } else if(i18n.language == "nl"){
            generalConditionsLink = "/assets/pdfs/Conditions generales_nl.pdf";
            officalFormLink =  config.API_BASE_URL + "/assets/Officiele Formulier.pdf";
            tipsLink = config.API_BASE_URL + "/assets/tipsnl.pdf";
        }

        return (
            <div>

                <div className="col-md-6 col-md-offset-3 text-center mx-auto" id="loginContainer">
                    <br/>
                    <br/>
                    <h2 className="register-text">
                        {siteName}&nbsp;
                        <Trans>
                            form
                        </Trans>
                    </h2>
                    <br/>
                    <form name="form mt-3" className="text-center" onSubmit={this.handleSubmit}>

                        {!this.IdUser &&
                            <div className={'form-group' + (submitted && !siteNumber ? ' has-error' : '')}>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    onBlur={this.handleFocusChange}
                                    className="form-field"
                                    name="siteNumber"
                                    value={siteNumber}
                                    label={i18n.t("site_number")}
                                    placeholder={i18n.t("site_number")}
                                />

                                {submitted && !siteNumber &&
                                <div className="help-block">
                                    <Trans>
                                        site_number_is_mandatory
                                    </Trans>
                                </div>
                                }
                            </div>
                        }


                        <div className={'form-group' + (submitted && !tableNumber ? ' has-error' : '')}>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                onChange={this.handleChange}
                                className="form-field"
                                name="tableNumber"
                                value={tableNumber}
                                label={i18n.t("table_number")}
                                placeholder={i18n.t("table_number")}
                                type="number"
                            />
                        </div>


                        <div className={'form-group'}>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                onChange={this.handleChange}
                                className="form-field"
                                name="duration"
                                value={duration}
                                label={i18n.t("duration_of_stay")}
                                placeholder={i18n.t("duration_of_stay")}
                                type="number"
                            />
                        </div>


                        <div className={'form-group' + (submitted && !firstAndLastName ? ' has-error' : '')}>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                onChange={this.handleChange}
                                className="form-field"
                                name="firstAndLastName"
                                value={firstAndLastName}
                                label={i18n.t("first_and_last_name")}
                                placeholder={i18n.t("first_and_last_name")}
                            />
                            {submitted && !firstAndLastName &&
                            <div className="help-block">
                            <Trans>
                                first_and_last_name_are_mandatory
                            </Trans>
                            </div>
                            }
                        </div>

                        <div>
                            <label>
                                <input  type="radio"
                                       name="PhoneOrEmailRadio"
                                        value="phone"
                                        checked={phoneChecked}
                                       onChange={this.handleRadioChange}  />
                                &nbsp;
                                <Trans>
                                    phone_number
                                </Trans>
                            </label>

                            &nbsp;&nbsp;&nbsp;&nbsp;

                            <label>
                                <input type="radio"
                                       name="PhoneOrEmailRadio"
                                       value="email"
                                       checked={emailChecked}
                                       onChange={this.handleRadioChange}  />
                                &nbsp;
                                <Trans>
                                    email
                                </Trans>
                            </label>
                        </div>



                        <div className={'form-group' + (submitted && !phoneOrEmail ? ' has-error' : '')}>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                onChange={this.handleChange}
                                className="form-field"
                                name="phoneOrEmail"
                                value={phoneOrEmail}
                                label={emailChecked ? i18n.t("email") : i18n.t("enter_your_phone")}
                                placeholder={emailChecked ? i18n.t("email") : i18n.t("enter_your_phone")}
                            />

                            {submitted && !phoneOrEmail &&
                            <div className="help-block">
                                <Trans>
                                    phone_or_email_is_mandatory
                                </Trans>
                            </div>
                            }
                        </div>

                        <div className="form-group mt-5">
                            <button className="landing-button has-bg" disabled={loading}>
                                <Trans>
                                    save
                                </Trans>
                            </button>
                            {loading &&
                            <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            }
                        </div>

                        <div
                            className={'form-group text-left mt-4'}
                        >
                            <div className="custom-control custom-checkbox">
                                <input
                                    checked={acceptPolicy}
                                    className="custom-control-input"
                                    type="checkbox"
                                    id="acceptPolicy"
                                    name="acceptPolicy"
                                    onChange={this.handleCheckboxChange}
                                />
                                <label
                                    className="custom-control-label text-black"
                                    htmlFor="acceptPolicy"
                                >
                                    <Trans>
                                        accept_belgian_snc
                                    </Trans>
                                </label>
                            </div>
                        </div>


                        <div
                            className={'form-group text-left mt-4'}
                        >
                            <div className="custom-control custom-checkbox">
                                <input
                                    checked={acceptTos}
                                    className="custom-control-input"
                                    type="checkbox"
                                    id="acceptTos"
                                    name="acceptTos"
                                    onChange={this.handleCheckboxChange}
                                />
                                <label
                                    className="custom-control-label text-black"
                                    htmlFor="acceptTos"
                                >
                                    <Trans>
                                        accept_tos
                                    </Trans>
                                </label>
                            </div>
                        </div>

                        {error &&
                        <div className={'alert alert-danger'}>{error}</div>
                        }

                    </form>

                    <br/><br/>

                </div>

                <div className="container" style={{ maxWidth: '700px', marginRight: 'auto', marginLeft: 'auto' }}>
                    <div className="row">


                        <div className="col-md-4 mt-3">
                            <a target="_blank" className="guide-links" href={tipsLink}>
                                <div className="row align-items-center top-buffer">
                                    <div className="col-md-4 col-3 pr-0 pr-md-2">
                                        <div className="pdf-placeholder text-center">
                                            <b>PDF</b>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <span className="guide-item-title">
                                            <Trans>
                                                info_pdf
                                            </Trans>
                                        </span>
                                        <br />
                                        <span className="guide-item-date">
                                            <Trans>
                                                info_pdf
                                            </Trans>
                                        </span>
                                    </div>
                                </div>
                            </a>
                        </div>


                        {(selectedLanguage == "nl" || selectedLanguage == "fr") &&
                        <div className="col-md-4 mt-3">
                            <a target="_blank" className="guide-links" href={officalFormLink}>
                                <div className="row align-items-center top-buffer">
                                    <div className="col-md-4 col-3 pr-0 pr-md-2">
                                        <div className="pdf-placeholder text-center">
                                            <b>PDF</b>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <span className="guide-item-title">
                                            <Trans>
                                                official_form
                                            </Trans>
                                        </span>
                                        <br />
                                        <span className="guide-item-date">
                                            <Trans>
                                                official_form
                                            </Trans>
                                        </span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        }


                        <div className="col-md-4 mt-3">
                            <a target="_blank" className="guide-links" href={generalConditionsLink}>
                                <div className="row align-items-center top-buffer">
                                    <div className="col-md-4 col-3 pr-0 pr-md-2">
                                        <div className="pdf-placeholder text-center">
                                            <b>PDF</b>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <span className="guide-item-title">
                                              <Trans>
                                                general_conditions
                                            </Trans>
                                        </span>
                                        <br />
                                        <span className="guide-item-date">
                                              <Trans>
                                                general_conditions
                                            </Trans>
                                        </span>
                                    </div>
                                </div>
                            </a>
                        </div>


                    </div>

                </div>


                <ModalThankYou
                    onHide={this.modalThankYouClose}
                    show={this.state.modalThankYouShow}
                />

            </div>
        );
    }



}




function isNumber(val) {
    return /^\d+$/.test(val);
}

function validatePhoneNumber(val) {
    return /^[0-9 +*]+$/.test(val);
}

function validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function allLetters(text) {
    let letters = /^[A-Z a-z]+$/;
    if(text.match(letters)) {
        return true;
    } else {
        return false;
    }
}


export { FormPage };