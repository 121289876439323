import React from 'react'
import Slide, {SlideContent} from './Slide'
import Header from '../Header'
import {ActionsButtonGroup, PrimaryButton} from '../Buttons'
import Footer from '../Footer'
import {toast} from "react-toastify";

import i18n from "i18next";
import { Trans } from 'react-i18next';


type Props = {
    isActive: boolean,
    slideRef: any,
    pathname: string,
}

export default ({isActive, slideRef, pathname}:Props)=>{
    return (
        <Slide className="HomeSlide" isActive={isActive} slideRef={slideRef}>
            <Header pathname={pathname} />
            <SlideContent>
                <div className="content">
                    <div className="hero-text">
                        <Trans>
                            landing_phrase
                        </Trans>
                    </div>

                    <PrimaryButton text={i18n.t('landing_horeca_button')} onClick={onPrimaryButtonClick} />

                </div>
            </SlideContent>
            <Footer />
        </Slide>
    )


    function onPrimaryButtonClick(){
        window.location.href = "register";
    }

    function onSecondaryButtonClick(){
        window.location.href = "form";
    }
}