import React from 'react';
import { Form } from 'react-bootstrap'
import { toast } from "react-toastify";

import { serverService } from '../../_services/server.service';

interface MyProps {
    onLanguageSelected(LanguageName: string): any,
    selectedLanguage?: string,
    languages?: any,
}

interface MyState {
    languages: any,
}

class LanguageSelect extends React.Component<MyProps, MyState> {

    constructor(props) {
        super(props);

        this.state = {
            languages: [],
        };
    }

    componentDidMount() {
        if (this.props.languages != null) {
            this.setState({ languages: this.props.languages });
        } else {
            this.setState({
                languages: [
                    {
                        'Name': 'en',
                        'title': 'EN'
                    },
                    {
                        'Name': 'fr',
                        'title': 'FR'
                    },
                    {
                        'Name': 'nl',
                        'title': 'NL'
                    }
                ]
            });
        }
    }

    render() {
        let { languages } = this.state;

        let optionItems = "";
        let defaultValue = 0;
        if (languages && languages.length > 0) {
            optionItems = languages.map((language) => {
                let selected = false;
                if (this.props.selectedLanguage == language.Name) {
                    selected = true;
                    defaultValue = language.Name;
                }
                return <option key={language.Name} value={language.Name} selected={selected}>{language && language.title ? language.title.toUpperCase() : ""}</option>
            });
        }

        return (
            <select
                onChange={this.onLanguageSelected}
                className="language-select"
            >
                {optionItems}
            </select>
        )
    }

    onLanguageSelected = (event) => {
        // this.setState({ selectedValueId: event.target.value });
        this.props.onLanguageSelected(event.target.value);
    };
}

export default LanguageSelect;
