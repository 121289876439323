import React from 'react';
import { toast } from "react-toastify";

import { Form, Button, ButtonToolbar } from "react-bootstrap";

import { serverService } from '../../_services/server.service';

import { Trans } from "react-i18next";
import i18n from "i18next";
import TextField from '@material-ui/core/TextField';

import PubSub from '../../_helpers/PubSub.js'

const $ = require('jquery');
$.DataTable = require('datatables.net');

require('datatables.net-bs4');
require('datatables.net-select-bs4');
require('datatables.net-buttons-bs4')();
require('datatables.net-buttons/js/buttons.html5.js')();


interface MyWindow extends Window {
    JSZip: any;
}
declare let window: MyWindow;

window.JSZip = require('jszip');

const moment = require('moment');



interface MyProps {
}

interface MyState {
    loading: boolean,
    answers: any,
    searchQuery: string
}

class AnswersTable extends React.Component<MyProps, MyState> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            answers: [],
            searchQuery: ''
        };

        this.handleChange = this.handleChange.bind(this);

    }
    componentDidMount() {

        let table = $(this.refs.main).DataTable({
            //dom: '<"data-table-wrapper"<"toolbar"><"bottom"f>rtip>',
            // "<'row'<'col-md-6 my-auto'<'toolbar'>><'col-md-6 data-table-search'f>>" +
            dom: "<'data-table-wrapper'" +
                "<'row'<'col-md-6 my-auto'<'toolbar'>><'col-md-6 data-table-search'>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-6'B><'col-6'p>>" +
                ">",
            data: this.state.answers,
            ordering: true,
            paging: true,
            buttons: [
                { extend: 'excel', text: i18n.t("export_excel") }
            ],
            "order": [[0, "desc"]],
            columns: [
                {
                    title: i18n.t("table_date"),
                    width: 60,
                    data: 'Added'
                },
                {
                    title: i18n.t("table_email_or_phone"),
                    width: 60,
                    data: 'Email'
                },
                {
                    title: i18n.t("table_table_number"),
                    width: 60,
                    data: 'TableNumber'
                },
                // {
                //     title: i18n.t("table_duration"),
                //     width: 60,
                //     data: 'Duration'
                // },
                {
                    title: i18n.t("table_first_and_last_name"),
                    width: 100,
                    data: 'FirstAndLastName'
                }
            ],
            language: {
                search: i18n.t("search"),
                emptyTable:  i18n.t("table_no_records"),
                paginate: {
                    first: i18n.t("first"),
                    previous: i18n.t("previous"),
                    next: i18n.t("next"),
                    last: i18n.t("last")
                }
            },

            columnDefs: [
                {
                    render: function (data, type, row) {
                        if (type === 'display' || type === 'filter') {
                            if (data != null) {
                                return moment(data).format('DD/MM/YYYY HH:mm:ss');
                            } else {
                                return "";
                            }
                        }
                        return data;
                    },
                    targets: 0,
                }
            ],
        });


        this.setState({
            loading: false,
            answers: [],
        });

        this.doFetchAll();


        PubSub.subscribe('languageChanged', (language) => {
            window.location.href = "/home";

        });

    }

    componentWillUnmount() {
        $('.data-table-wrapper')
            .find('table')
            .DataTable()
            .destroy(true);

        PubSub.unsubscribe('languageChanged');
    }


    componentDidUpdate(oldProps) {
        if (this.state.answers !== oldProps.answers) {
            reloadTableData(this.state.answers);
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        let searchQuery = this.state.searchQuery;
        searchQuery = value;
        this.setState({ searchQuery });
    }

    render() {
        return (
            <div
                className="mx-auto"
                style={{
                    maxWidth: '832px',
                    width: '98%'
                }}
            >
                <div className="row">
                    <div className="col-md-12">
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            name="searchQuery"
                            value={this.state.searchQuery}
                            onChange={this.handleChange}
                            className="form-field is-search"
                            label={i18n.t("search")}
                            placeholder={i18n.t("search")}
                        />
                    </div>
                    <div className="col-md-12" id="answersTableContainer">
                        <table ref="main" className="table table-bordered" />
                    </div>
                </div>
            </div>);
    }


    doFetchAll() {

        let userStr = localStorage.getItem('user');
        let IdCompany = 0;
        if (userStr) {
            let userObj = JSON.parse(userStr);
            IdCompany = userObj.IdCompany;
        }

        this.setState({ loading: true });
        serverService.getAnswers()
            .then(
                answersRes => {
                    if (answersRes && answersRes.data != undefined) {
                        this.setState({ answers: answersRes.data, loading: false })
                    } else {
                        this.setState({ loading: false })
                    }
                },
                error => {
                    toast.warn("Did not get results for company");
                    this.setState({ loading: false });
                }
            );
    }

}


function reloadTableData(answers) {
    const table = $('.data-table-wrapper')
        .find('table')
        .DataTable();
    table.clear();
    table.rows.add(answers);
    table.draw();

    let toolbar = $("#toolbar");
    $("div.toolbar").html(toolbar);


    table.columns.adjust().draw();
}


export default AnswersTable;
