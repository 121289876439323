import React from 'react';

import { serverService } from '../../_services/server.service';
import { toast } from "react-toastify";
import { Row,Col,Button } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";

import { Trans } from 'react-i18next';
import i18n from "i18next";

import SupportSlide, { FieldValues } from "./SupportSlide";

let sha1 = require('sha1');

const RECAPTCHA_SITE_KEY = "6LdDLcAZAAAAAEehFoVAZNFwSFcguNV1AOXx5Mkw"; // replace this value with your recaptcha site key

interface MyProps {
}

interface MyState {
    submitted: boolean,
    loading: boolean,
    error: string,
}


class SupportPage extends React.Component<MyProps & RouteComponentProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',
        };

    }


    render() {
        return (
            <div className="App">
                <SupportSlide isActive={true} slideRef={null} onSubmitQuestion={handleSendQuestion} reCaptchaSiteKey={RECAPTCHA_SITE_KEY} />
            </div>
        );
    }

}

function handleSendQuestion(inputData: FieldValues) {
    // you can do what ever you wish with the entered data here.
    // if this function is called, then all input has been validated
    // and captcha has been solved.

    serverService.sendMailToSupport(inputData).then(result => {
        if(result.status == 1){
            toast.success(i18n.t("msg_mail_to_support"));
            setTimeout(function(){
                window.location.href = "/hello";
            }, 3000);
        } else {
            toast.warn(i18n.t("something_went_wrong"));
        }
    },error => {
        toast.error(i18n.t("something_went_wrong"));
        console.log(error);
    });
    console.log("Entered data: ", inputData);
}


export default SupportPage;