/**
 * Created by alexd on 8/5/2020.
 */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './res/locales/translation-en.json';
import translationFR from './res/locales/translation-fr.json';
import translationNL from './res/locales/translation-nl.json';


// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: translationEN
    },
    fr: {
        translation: translationFR
    },
    nl: {
        translation: translationNL
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: 'en',
        resources,

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;